import React from "react";
import { WrapperContact, Container, Row, Heading, Strong, Table } from '../Header/Header.styles';

function Contact({isOn}) {
    return (
        <>
        <WrapperContact className={isOn ? "inverse-dark-contact" : ""}>
            <Container>
                <Row>
                    <Heading>Lets Find the Right <Strong>Solution</Strong></Heading>
                    <Table>
                        <tbody>
                            <tr>
                                <td><i className="fa fa-map-marker" style={{"fontSize":"38px", "color":"#00aeef", "margin":"10px 20px"}}></i></td>
                                <td>Grude 88340, Bosnia and Herzegovina</td>
                            </tr>
                            <tr>
                                <td><i className="fa fa-envelope" style={{"fontSize":"28px", "color":"#00aeef", "margin":"10px 20px"}}></i></td>
                                <td>info@signitive.ba</td>
                            </tr>
                            <tr>
                                <td><i className="fa fa-globe" style={{"fontSize":"28px", "color":"#00aeef", "margin":"10px 20px"}}></i></td>
                                <td>www.signitive.ba</td>
                            </tr>
                            <tr>
                                <td><i className="fa fa-credit-card" style={{"fontSize":"28px", "color":"#00aeef", "margin":"10px 20px"}}></i></td>
                                <td> VAT ID 272455100008</td>
                            </tr>
                        </tbody>
                    </Table>
                </Row>
            </Container>
        </WrapperContact>
        </>
    );
}

export default Contact;