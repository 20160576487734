import React from 'react';
import { Wrapper, Container, Row, Heading, Strong, Paragraph, Button } from './Header.styles';
import PhpLogo from '../../images/php-logo.svg';
import GoLogo from '../../images/go-logo.svg';
import MysqlLogo from '../../images/mysql-logo.svg';
import MariaDbLogo from '../../images/mariadb-logo.svg';
import GitLogo from '../../images/git-logo.svg';
import ElasticLogo from '../../images/elasticsearch-logo.svg';
import RabbitMqLogo from '../../images/rabbitmq-logo.svg';
import ReactjsLogo from '../../images/reactjs-logo.svg';
import BootstrapLogo from '../../images/bootstrap-logo.svg';
import JqueryLogo from '../../images/jquery-logo.svg';

function Header({isOn}) {
    return (
        <Wrapper className={isOn ? "inverse-dark-home" : ""}>
            <Container>
                <Row>
                    <Heading className="line-1 anim-typewriter"><Strong>Signitive</Strong> Smart Solutions </Heading>
                    <Paragraph>
                        Providing modern web based technology solutions for your business
                    </Paragraph>
                    <Button className="magic-hover" to="/about">About Us</Button>
                    <Button className="magic-hover" to="/contact">Contact Us</Button>
                    <div style={{"marginTop":"150px"}}>
                        <div className="technologies-home">
                            <span><img src={PhpLogo} width="50" alt="php-logo"/></span>
                            <span><img src={GoLogo} width="50" alt="go-logo"/></span>
                            <span><img src={MysqlLogo} width="70" alt="mysql-logo"/></span>
                            <span><img src={MariaDbLogo} width="80" alt="mariadb-logo"/></span>
                            <span><img src={GitLogo} width="50" alt="git-logo"/></span>
                        </div>
                        <div className="technologies-home">
                            <span><img src={ElasticLogo} width="90" alt="elasticsearch-logo"/></span>
                            <span><img src={RabbitMqLogo} width="70" alt="rabbitmq-logo"/></span>
                            <span><img src={ReactjsLogo} width="70" alt="reactjs-logo"/></span>
                            <span><img src={BootstrapLogo} width="70" alt="bootstrap-logo"/></span>
                            <span><img src={JqueryLogo} width="70" alt="jquery-logo"/></span>
                        </div>
                    </div>
                </Row>
            </Container>
        </Wrapper>
    );
}

export default Header;