import React, { useEffect } from "react";
import magicMouse from "magicmouse.js";
import Navbar from "./components/NavBar";
import Header from "./components/Header";
import Footer from "./components/Footer";
import NotFound from "./components/NotFound.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from './components/About';
import Contact from './components/Contact';
import { GlobalStyle } from './GlobalStyle';
import useToggle from "./hooks/useToggle";
import CookieConsent from "react-cookie-consent";

function App() {

    useEffect(() => {
        const options = {
            "cursorOuter": "circle-basic",
            "hoverEffect": "pointer-overlay",
            "hoverItemMove": true,
            "defaultCursor": false,
            "outerWidth": 30,
            "outerHeight": 30
        };

        magicMouse(options);
        
    }, []);

    const [isOn, toggleIsOn] = useToggle();

    if (isOn) {
        document.body.classList.add('inverse-dark-body');
    } else {
        document.body.classList.remove('inverse-dark-body');
    }

    return (
        <Router>
            <Navbar isOn={isOn} toggleIsOn={toggleIsOn} />
            <Routes>
                <Route path="/" element={<Header isOn={isOn} />} />
                <Route path="/about" element={<About isOn={isOn} />} />
                <Route path="/contact" element={<Contact isOn={isOn} />} />
                <Route path="/*" element={<NotFound />} />
            </Routes>
            <Footer isOn={isOn} />
            <GlobalStyle />
            <CookieConsent location="bottom" cookieName="signitiveCookie" expires={999} overlay>
                This website uses cookies to enhance the user experience.
            </CookieConsent>
        </Router>
    );
}

export default App;