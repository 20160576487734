import styled from 'styled-components';
import { NavLink as Link } from "react-router-dom";
import BackgroundHome from '../../images/header_3.jpg';
import BackgroundAbout from '../../images/header_12.jpg';
import BackgroundContact from '../../images/contact.jpg';

export const Wrapper = styled.div`
    height: 1000px;
    background-image: linear-gradient(to right,rgba(255, 255, 255, 0.98) 33%, rgba(255, 255, 255, 0.6) 66%, rgba(255, 255, 255, 0.01) 100%), url(${BackgroundHome});
`;

export const WrapperAbout = styled.div`
    height: 700px;
    background-image: linear-gradient(to right,rgba(255, 255, 255, 0.98) 33%, rgba(255, 255, 255, 0.8) 66%, rgba(255, 255, 255, 0.05) 100%), url(${BackgroundAbout});
`;

export const WrapperContact = styled.div`
    height: 1000px;
    background-image: linear-gradient(to right,rgba(255, 255, 255, 0.98) 33%, rgba(255, 255, 255, 0.8) 66%, rgba(255, 255, 255, 0.05) 100%), url(${BackgroundContact});
`;

export const Container = styled.div`
    display: block;
    overflow: visible;
    padding: 10em 0 14em 0;
    position: relative;
    height: 280px;
`;

export const Row = styled.div`
    padding: 0 15px;
    margin: 0 auto;
    min-width: 300px;
    text-align: center;
`;

export const Heading = styled.h2`
    color: #2d3032;
    font-size: 68px;
    font-weight: 300;
    text-transform: capitalize;
    display: block;
    position: relative;
    line-height: 120%;
    letter-spacing: 0;
`;

export const Strong = styled.strong`
    color: #00aeef;
    font-weight: 700;
    font-size: 68px;
`;

export const Paragraph = styled.p`
    padding-bottom: 35px;
    font-size: 21px;
    font-weight: 300;
    padding: 0 0 40px;
    margin: 0;
    line-height: inherit;
    color: #2d3032;
`;

export const Table = styled.table`
    padding-bottom: 35px;
    font-size: 21px;
    font-weight: 300;
    padding: 0 0 40px;
    margin: 0 auto;
    line-height: inherit;
    color: #2d3032;
`;

export const Button = styled(Link)`
    margin: 20px 20px;
    background-color: #2d3032;
    color: #ffffff;
    border: 2px solid #2d3032;
    padding: 13px 40px;
    font-size: 18px;
    border-radius: 4px;
    transition: background 0.2s, color 0.2s;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    position: relative;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1.42857143;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    box-shadow: 0 0 0 2px #2d3032;
    box-sizing: border-box;
    text-decoration: none;

    :after {
        pointer-events: none;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        content: '';
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
        top: -7px;
        left: -7px;
        padding: 7px;
        box-shadow: 0 0 0 2px #2d3032;
        transition: transform 0.2s, opacity 0.2s;
        transform: scale(.8);
        opacity: 0;
    }

    :hover:after {
        transform: scale(1);
        opacity: 1;
    }
`;

