import { createGlobalStyle } from 'styled-components';
import BackgroundHome from './images/header_3.jpg';
import BackgroundAbout from './images/header_12.jpg';
import BackgroundContact from './images/contact.jpg';

export const GlobalStyle = createGlobalStyle`
    :root {
        --maxWidht: 1280px;
        --white: #FFFFFF;
        --lightGrey: #eee;
        --medGrey: #353535;
        --darkGrey: #1c1c1c;
        --fontSuperBig: 2.5rem;
        --fontBig: 1.5rem;
        --fontMed: 1.2rem;
        --fontSmall: 1rem;
    }

    * {
        font-family: 'PT Sans', sans-serif;
    }

    html, body {
        max-width: 100%;
        overflow-x: hidden;
    }

    body {
        margin: 0;
        padding: 0;
        color: #999;
        font-size: 15px;
        line-height: 1.80857;
    }

    .technologies-home {
        text-align: center;
        margin: 25px 0;
    }

    .technologies-home > span {
        padding: 0 30px;
        width: 100px;
    }

    .technologies-about {
        text-align: center;
        margin: 25px 0;
    }

    .technologies-about > span {
        padding: 0 30px;
        width: 100px;
    }

    .technologies-home img {
        transition: transform 2s, filter 1.5s ease-in-out;
        transform-origin: center center;
        filter: brightness(50%);
    }

    .technologies-home img:hover {
        filter: brightness(100%);
        transform: scale(1.3);
    }

    td {
        padding: 5px 5px;
        text-align: left;
    }

    body #magicMouseCursor {
        border: 1px solid #35aeef !important;
    }

    body #magicPointer {
        background: #35aeef !important;
    }

    body #magicPointer .is-hover {
        background: #35aeef !important;
    }

    .line-1 {
        position: relative;
        top: 10%;
        width: 11.5em;
        margin: 0 auto;
        border-right: 2px solid rgba(25, 25, 25, 0.75);
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        transform: translateY(-50%);
    }

    .anim-typewriter {
        animation: typewriter 4s steps(27) 1s 1 normal both,
          blinkTextCursor 0.5s steps(27) infinite normal;
    }

    @keyframes typewriter {
        from {
          width: 0;
        }
        to {
          width: 11.5em;
        }
    }

    @keyframes blinkTextCursor {
        from {
          border-right-color: rgba(25, 25, 25, 0.75);
        }
        to {
          border-right-color: transparent;
        }
    }

    @media screen and (max-width: 799px) {
        .switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 34px;
            left: 10%;
            bottom: 46%;
        }
    }

    @media screen and (min-width: 800px) {
        .switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 34px;
            left: 67%;
            bottom: 46%;
        }
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--primary-color);
        -webkit-transition: 0.4s;
        transition: 0.4s;
        box-shadow: 0 0 2px grey;
    }
    
    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: #35aeef;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
    input:checked + .slider {
        background-color: #000;
    }

    input:focus + .slider {
        box-shadow: 0 0 2px grey;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
    
    .slider.round {
        border-radius: 34px;
    }
    
    .slider.round:before {
        border-radius: 50%;
    }

    .inverse-dark {
        background: #080808 !important;
    }

    .inverse-dark-body {
        background: #cbcbcb !important;
    }

    .inverse-dark-home {
        background-image: linear-gradient(to right,rgb(102 102 102 / 98%) 33%,rgb(135 135 135 / 60%) 66%,rgba(255,255,255,0.01) 100%), url(${BackgroundHome});
    }
    
    .inverse-dark-about {
        background-image: linear-gradient(to right,rgb(102 102 102 / 98%) 33%,rgb(135 135 135 / 60%) 66%,rgba(255,255,255,0.01) 100%), url(${BackgroundAbout});
    }
    
    .inverse-dark-contact {
        background-image: linear-gradient(to right,rgb(102 102 102 / 98%) 33%,rgb(135 135 135 / 60%) 66%,rgba(255,255,255,0.01) 100%), url(${BackgroundContact});
    }
`;