import React from "react";
import { Link } from "react-router-dom";
// Styles
import CompanyLogo from '../../images/reactjs-icon.svg';
import { Header, Nav,Container, NavLogo, NavLink, NavMenu } from "./NavBar.styles";

function Navbar({isOn, toggleIsOn}) {

    return (
        <>
           <Header className={isOn ? "inverse-dark" : ""}>
               <Nav>
                   <Container>
                        <Link className="magic-hover" to="/">
                            <NavLogo src={CompanyLogo} alt="signitive-logo" width="50" />
                        </Link>
                        <NavMenu>
                            <NavLink className="magic-hover" to="/" activestyle="true">Home</NavLink>
                            <NavLink className="magic-hover" to="/about" activestyle="true">About</NavLink>
                            <NavLink className="magic-hover" to="/contact" activestyle="true">Contact</NavLink>
                            <label className="switch">
                                <input id="mode-switch" onClick={toggleIsOn} type="checkbox"/>
                                <span className="slider round magic-hover"></span>
                            </label>
                        </NavMenu>
                    </Container>
                </Nav>
           </Header>
        </>
    );
};

export default Navbar;