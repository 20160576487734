import React from "react";
// Styles
import { Container, CopyRights, FooterDistributed, FooterCompanyName } from "./Footer.styles";

function Footer({isOn}) {
    return (
        <>
            <CopyRights className={isOn ? "inverse-dark" : ""}>
                <Container>
                    <FooterDistributed>
                        <FooterCompanyName>{(new Date().getFullYear())} &copy; Signitive - Smart solutions.</FooterCompanyName>
                    </FooterDistributed>
                </Container>
            </CopyRights>
        </>
    );
}

export default Footer;