import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Header = styled.header`
    background: #FFFFFF;
    position: relative;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 12%);
    display: block;
    left: 0;
    padding: 20px 0;
    right: 0;
    top: 0;
    width: 100%;
`;

export const Nav = styled.nav`
    margin-bottom: 0;
    background-color: transparent;
    border: 0;
    border-radius: 0px;
    position: relative;
    min-height: 50px;
    display: block;
`;

export const Container = styled.div`
    padding: 0 15px;
    margin: 0 auto;
    box-sizing: border-box;
    width: 950px;
    min-width: 300px;

    @media screen and (max-width: 950px) {
        width: 600px;
    }

    @media screen and (max-width: 600px) {
        width: 200px;
    }
`;

export const NavLogo = styled.img`
    float: left;
    margin: 0px 10px;
    
    :hover {
        opacity: 0.5;
        transition: all .5s ease-in-out;
    }

    @media screen and (max-width: 600px) {
        display: none;
    }
`;

export const NavLink = styled(Link)`
    color: grey;
    background-color: transparent;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 20px;
    position: relative;
    display: block;
    padding: 10px 15px;
    text-decoration: none;
    outline: none;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;

    &.active {
        background: transparent;
        color: #00aeef;
        border-color: #00aeef;
    }

    &:hover {
        color: #00aeef;
        transition: all .3s ease-in-out;
    }
`;

export const Bars = styled(FaBars)`
    display: none;
    color: #000;

    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 75%);
        font-size: 1.8rem;
        cursor: pointer;
    }
`;

export const NavMenu = styled.div`
    margin: auto 0;
    padding: 5px 0;
    display: flex;
    align-items: center;
    @media screen and (max-width: 600px) {
        float: none;
    }
`;

export const NavBtn = styled.div`
    display: flex;
    float: right;
    margin-left: 20px;
`;

export const NavBtnLink = styled(Link)`
    border-radius: 4px;
    background: transparent;
    padding: 10px 22px;
    color: #1f1f1f;
    outline: none;
    border: 1px solid #efefef;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    margin-left: 24px;
    &:hover {
        transition: all 0.2s ease-in-out;
        background: #00aeef;
        color: #fff;
        border-color: #00aeef;
    }
`;
