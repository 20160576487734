import React from "react";

const NotFound = () => <div
    style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    }}
>
    <h1>404 Not Found</h1>
</div>;

export default NotFound;