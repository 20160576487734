import styled from "styled-components";

export const Wrapper = styled.footer`
    padding: 90px 0 80px;
    color: #999;
    background-color: #1f1f1f;
`;

export const Container = styled.div`
    padding: 0 15px;
    margin: 0 auto;
    box-sizing: border-box;
    width: 950px;
    min-width: 300px;
    @media screen and (max-width: 950px) {
        width: 600px;
    }
    @media screen and (max-width: 600px) {
        width: 200px;
    }
`;

export const Row = styled.div`
    padding: 0 15px;
    margin: 0 auto;
    min-width: 300px;
    margin-right: -15px;
    margin-left: -15px;
    text-align: center;
`;

export const CopyRights = styled.div`
    border-top: 1px dashed rgba(255, 255, 255, 0.1);
    background-color: #ffffff;
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    overflow: hidden;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 12%);
`;

export const FooterDistributed = styled.div`
    box-sizing: border-box;
    text-align: left;
    display: block;
    text-align: center;
`;

export const FooterCompanyName = styled.p`
    font-weight: 300;
    margin: 20px 10px;
    color: #666;
    padding: 19px 0px;
`;