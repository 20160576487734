import React from "react";
import { WrapperAbout, Container, Row, Heading, Strong, Button } from '../Header/Header.styles';
import { Section, SectionContainer, SectionTitle } from './About.styles';
import PhpLogo from '../../images/php-logo.svg';
import GoLogo from '../../images/go-logo.svg';
import MysqlLogo from '../../images/mysql-logo.svg';
import MariaDbLogo from '../../images/mariadb-logo.svg';
import GitLogo from '../../images/git-logo.svg';
import ElasticLogo from '../../images/elasticsearch-logo.svg';
import RabbitMqLogo from '../../images/rabbitmq-logo.svg';
import ReactjsLogo from '../../images/reactjs-logo.svg';
import BootstrapLogo from '../../images/bootstrap-logo.svg';
import JqueryLogo from '../../images/jquery-logo.svg';

function About({isOn}) {

    return (
        <>
        <WrapperAbout className={isOn ? "inverse-dark-about" : ""}>
            <Container>
                <Row>
                    <Heading><Strong>We improve </Strong> the business</Heading>
                    <Button className="magic-hover" to="/contact">Get in touch</Button>
                </Row>
            </Container>
        </WrapperAbout>
        <Section>
            <SectionContainer>
                <SectionTitle>Who we are?</SectionTitle>
                Signitive d.o.o. company consists of a small team of developers. We specialise in writing custom software to our clients.
                We are experienced in creating business-critical, enterprise-wide, client-server applications that manage client’s business processes.
                We understand our customers and acknowledge the fact that Technology is there to support the business, not the other way round.<br/><br/>
                <div className="technologies-about">
                    <span><img src={PhpLogo} width="50" alt="php-logo"/></span>
                    <span><img src={GoLogo} width="50" alt="go-logo"/></span>
                    <span><img src={MysqlLogo} width="70" alt="mysql-logo"/></span>
                    <span><img src={MariaDbLogo} width="80" alt="mariadb-logo"/></span>
                    <span><img src={GitLogo} width="50" alt="git-logo"/></span>
                </div>
                <div className="technologies-about">
                    <span><img src={ElasticLogo} width="90" alt="elasticsearch-logo"/></span>
                    <span><img src={RabbitMqLogo} width="70" alt="rabbitmq-logo"/></span>
                    <span><img src={ReactjsLogo} width="70" alt="reactjs-logo"/></span>
                    <span><img src={BootstrapLogo} width="70" alt="bootstrap-logo"/></span>
                    <span><img src={JqueryLogo} width="70" alt="jquery-logo"/></span>
                </div>
            </SectionContainer>
        </Section>
        </>
    );
}

export default About;