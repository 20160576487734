import styled from "styled-components";

export const Section = styled.div`
    display: block;
    position: relative;
    overflow: hidden;
    padding: 120px 0;
    margin: 0 auto;
    width: 950px;
    min-width: 300px;

    @media screen and (max-width: 950px) {
        width: 600px;
    }

    @media screen and (max-width: 600px) {
        width: 200px;
    }
`;

export const SectionContainer = styled.div`
    padding-right: 15px;
    padding-left: 15px;
    margin: 0 auto;
`;

export const SectionTitle = styled.div`
    font-size: 42px;
    font-weight: 300;
    line-height: 62px;
    margin: 0 0 25px;
    padding: 0;
    text-transform: none;
    color: black;
    text-align: center;
`;